import React from "react";
import Image from "gatsby-image";
import { Link } from "gatsby";
import SEO from "../components/seo";
import BlockContent from "@sanity/block-content-to-react";
import moment from "moment";

/* Artist Page
============================================================================= */

const ArtistPage = ({ pageContext }) => {
	// Config
	const { data } = pageContext;

	// Render
	return (
		<>
			<SEO title={data.name} />
			<div className="w-full flex lg:flex-row flex-col">
				<ArtistDetails data={data} />
				<div className="lg:w-1/2 w-full">
					<Image
						fluid={data.image.asset.fluid}
						className="w-full h-220"
					/>
				</div>
			</div>
		</>
	);
};

/* Artist Details
============================================================================= */

const ArtistDetails = ({ data }) => {
	// Config
	const { _rawAbout: about, name } = data;
	const startDate = new moment(data.startDate);
	const endDate = new moment(data.endDate);

	// Render
	return (
		<div className="lg:w-1/2 w-full pl-10 pr-32 pt-24 pb-4 flex flex-col">
			<h2 className="text-darkblue text-5xl font-sans font-black pb-3 ibm-plex">
				{name.toUpperCase()}
			</h2>
			<a
				href={`${data.insta}`}
				target="_blank"
				rel="noopener noreferrer"
				className="text-darkblue text-2xl font-sans pb-1 ibm-plex"
			>
				{data.instaHandle}
			</a>
			<h4 className="text-darkblue text-base font-sans pb-6 ibm-plex">
				{startDate.format("MMMM DD")} | {startDate.format("HH:mm")} -{" "}
				{endDate.format("HH:mm")}
			</h4>
			<p className="text-darkblue text-base font-sans pb-6 ibm-plex block-content">
				<BlockContent blocks={about} />
			</p>
			<div className="flex h-full w-full flex-col items-start justify-end hidden">
				<h4 className="text-darkblue text-lg font-sans pb-2 ibm-plex italic">
					Live In
				</h4>
				<h4 className="text-darkblue text-2xl font-sans pb-6 ibm-plex">
					<strong>04</strong> HOURS | <strong>32</strong> MINS |{" "}
					<strong>48</strong> SECS
				</h4>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default ArtistPage;
